import React, { useState, useEffect } from 'react';

// Add this constant at the top of your file, just below the imports
const SHOW_CONSTRUCTION_MESSAGE = 0; // Set to 1 to show the message, 0 to hide it

const App = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    originalLanguage: '',
    targetLanguage: '',
    email: '',
    file: null
  });
  const [errors, setErrors] = useState({});
  const [processing, setProcessing] = useState(false);
  const [popup, setPopup] = useState({ show: false, message: '', isError: false });

  useEffect(() => {
    if (popup.show) {
      const timer = setTimeout(() => {
        setPopup({ show: false, message: '', isError: false });
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [popup.show]);

  const showPopup = (message, isError = false) => {
    setPopup({ show: true, message, isError });
  };

  const handleLogin = () => {
    if (password === process.env.REACT_APP_PASSWORD) {
      setLoggedIn(true);
    } else {
      showPopup('Incorrect password', true);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleLogin();
    }
  };

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: files ? files[0] : value
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.originalLanguage) newErrors.originalLanguage = 'Original language is required';
    if (!formData.targetLanguage) newErrors.targetLanguage = 'Target language is required';
    if (!formData.email) newErrors.email = 'Email is required';
    if (!formData.email.match(/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/)) {
      newErrors.email = 'Invalid email format';
    }
    if (!formData.file) newErrors.file = 'File is required';
    if (formData.originalLanguage === formData.targetLanguage) {
      newErrors.language = 'Original and target languages must be different';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      setProcessing(true);
      
      const apiUrl = 'https://api.twinai.nl/graphql';
      const authToken = 'roul-oddys-key-super-secret';
  
      const query = `
        mutation CreateFile {
          createFile(input: {}) {
            id
            uploadLocation
          }
        }
      `;
  
      try {
        // First API call
        console.log('Making first API call...');
        const response = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `bearer ${authToken}`
          },
          body: JSON.stringify({ query })
        });
  
        const rawResponse = await response.text();
        console.log('Raw API response (1st call):', rawResponse);
  
        let data;
        try {
          data = JSON.parse(rawResponse);
        } catch (parseError) {
          console.error('JSON Parse Error (1st call):', parseError);
          throw new Error('Failed to parse JSON response from first API call');
        }
  
        console.log('Parsed API response (1st call):', data);
  
        if (!response.ok) {
          throw new Error(`HTTP error in first API call! status: ${response.status}`);
        }
  
        if (!data.data || !data.data.createFile) {
          throw new Error('Unexpected response structure from first API call');
        }
  
        const { id, uploadLocation } = data.data.createFile;
  
        // Second API call to upload the file
        console.log('Making second API call (file upload)...');
        const formDataForUpload = new FormData();
        formDataForUpload.append('file', formData.file);
  
        const uploadResponse = await fetch(uploadLocation, {
          method: 'POST',
          headers: {
            'Authorization': `bearer ${authToken}`
          },
          body: formDataForUpload
        });
  
        console.log('Upload response status:', uploadResponse.status);
  
        if (!uploadResponse.ok) {
          throw new Error(`Upload failed with status: ${uploadResponse.status}`);
        }
  
        console.log('File upload successful');
  
        // Third API call
        console.log('Making third API call (workflow execution)...');
        const workflowUrl = 'https://api.twinai.nl/v1/workflows/cc3f0274-0696-42eb-8bee-1604a57e071e/run';
        const workflowResponse = await fetch(workflowUrl, {
          method: 'POST',
          headers: {
            'Authorization': `bearer ${authToken}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            input: {
              fileId: id,
              or: formData.originalLanguage,
              cc: formData.targetLanguage,
              mail: formData.email
            }
          })
        });
  
        const rawWorkflowResponse = await workflowResponse.text();
        console.log('Raw API response (3rd call - workflow):', rawWorkflowResponse);
  
        let workflowResult;
        try {
          workflowResult = JSON.parse(rawWorkflowResponse);
        } catch (parseError) {
          console.error('JSON Parse Error (3rd call):', parseError);
          throw new Error('Failed to parse JSON response from workflow execution');
        }
  
        console.log('Parsed API response (3rd call):', workflowResult);
  
        if (!workflowResponse.ok) {
          throw new Error(`Workflow execution failed with status: ${workflowResponse.status}`);
        }
  
        setProcessing(false);
        showPopup('Translation completed and sent to your email.');
  
      } catch (error) {
        console.error('Error in API calls:', error);
        setProcessing(false);
        showPopup(`An error occurred while processing your file: ${error.message}`, true);
      }
    }
  };

  const PopupMessage = () => (
    <div className={`fixed top-4 right-4 p-4 rounded-md ${popup.isError ? 'bg-red-200 text-red-800' : 'bg-green-200 text-green-800'}`}>
      {popup.message}
    </div>
  );

  if (!loggedIn) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-100 relative">
        <div className="absolute top-4 left-4">
          <img src="https://hellomarketing.ai/wp-content/uploads/2024/06/HelloMarketing-2.svg" alt="HelloMarketing Logo" className="h-12" />
        </div>
        <div className="p-8 bg-white rounded-lg shadow-md w-full max-w-md">
          <img src="https://www.farmfrites.com/static/logo.png" alt="Farm Frites Logo" className="mx-auto mb-6 h-20" />
          <h2 className="text-3xl font-bold mb-6 text-center">Translate app login</h2>
          {SHOW_CONSTRUCTION_MESSAGE === 1 && (
            <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-4" role="alert">
              <p className="font-bold">Under Construction</p>
              <p>This site is currently under development. Some functionalities may not work optimally.</p>
            </div>
          )}
          <div className="relative">
            <input
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onKeyPress={handleKeyPress}
              placeholder="Enter password"
              className="w-full p-2 pr-10 mb-4 border rounded"
            />
            <button
              onClick={() => setShowPassword(!showPassword)}
              className="absolute inset-y-0 right-0 pr-3 flex items-center justify-center text-sm leading-5 w-10 -mt-3"
            >
              <span className="text-xl">{showPassword ? '🙈' : '👁️'}</span>
            </button>
          </div>
          <button 
            onClick={handleLogin}
            className="w-full p-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            Login
          </button>
        </div>
        {popup.show && <PopupMessage />}
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-2xl w-full space-y-8">
        <div className="bg-white p-8 rounded-lg shadow-md mb-8">
          <h1 className="text-3xl font-bold mb-4 text-center">HelloMarketing.ai Translation</h1>
          <img src="https://www.farmfrites.com/static/logo.png" alt="Farm Frites Logo" className="mx-auto mb-8 h-20" />
          <p className="mb-4 text-center">
            The format that is accepted can be found <a href="https://docs.google.com/spreadsheets/d/1y0hE2KPY764VPenW7YwUEgui2frUTX27/edit?usp=sharing&ouid=105583792600626654096&rtpof=true&sd=true" className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">here</a>
          </p>
          <div className="bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-4 mb-4" role="alert">
            <p className="font-bold">Language support information</p><br></br>
            <p><u>Fully supported languages:</u> Dutch, English, French, Polish</p>
            <p><u>Beta languages:</u> Spanish, Portuguese, Italian, Finnish, Swedish, Danish, Norwegian</p><br></br>
            <p><em>Note: Beta languages are in testing phase and may have lower accuracy.</em></p>
          </div>
        </div>
        <div className="bg-white p-8 rounded-lg shadow-md">
          <div className="space-y-6">
            <div>
              <label className="block mb-2">Original Language</label>
              <select
                name="originalLanguage"
                value={formData.originalLanguage}
                onChange={handleInputChange}
                className="w-full p-2 border rounded"
              >
                <option value="">Select Original Language</option>
                <option value="Dutch">Dutch</option>
                <option value="English">English</option>
                <option value="French">French</option>
                <option value="Polish">Polish</option>
                <option value="Spanish">Spanish (Beta)</option>
                <option value="Portuguese">Portuguese (Beta)</option>
                <option value="Italian">Italian (Beta)</option>
                <option value="Finnish">Finnish (Beta)</option>
                <option value="Swedish">Swedish (Beta)</option>
                <option value="Danish">Danish (Beta)</option>
                <option value="Norwegian">Norwegian (Beta)</option>
              </select>
              {errors.originalLanguage && <p className="text-red-500 mt-1">{errors.originalLanguage}</p>}
            </div>

            <div>
              <label className="block mb-2">Target Language</label>
              <select
                name="targetLanguage"
                value={formData.targetLanguage}
                onChange={handleInputChange}
                className="w-full p-2 border rounded"
              >
                <option value="">Select Target Language</option>
                <option value="Dutch">Dutch</option>
                <option value="English">English</option>
                <option value="French">French</option>
                <option value="Polish">Polish</option>
                <option value="Spanish">Spanish (Beta)</option>
                <option value="Portuguese">Portuguese (Beta)</option>
                <option value="Italian">Italian (Beta)</option>
                <option value="Finnish">Finnish (Beta)</option>
                <option value="Swedish">Swedish (Beta)</option>
                <option value="Danish">Danish (Beta)</option>
                <option value="Norwegian">Norwegian (Beta)</option>
              </select>
              {errors.targetLanguage && <p className="text-red-500 mt-1">{errors.targetLanguage}</p>}
            </div>

            <div>
              <label className="block mb-2">Email</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                placeholder="Enter email"
                className="w-full p-2 border rounded"
              />
              {errors.email && <p className="text-red-500 mt-1">{errors.email}</p>}
            </div>

            <div>
              <label className="block mb-2">File Upload</label>
              <input
                type="file"
                name="file"
                onChange={handleInputChange}
                accept=".xlsx"
                className="w-full p-2 border rounded"
              />
              {errors.file && <p className="text-red-500 mt-1">{errors.file}</p>}
            </div>

            {errors.language && <p className="text-red-500">{errors.language}</p>}

            <button
              onClick={handleSubmit}
              disabled={processing || Object.values(formData).some(value => !value) || formData.originalLanguage === formData.targetLanguage}
              className="w-full p-2 bg-blue-500 text-white rounded hover:bg-blue-600 disabled:bg-gray-400"
            >
              {processing ? 'Processing...' : 'Translate'}
            </button>
          </div>
        </div>
        <div className="flex justify-center mt-8">
          <img src="https://hellomarketing.ai/wp-content/uploads/2024/06/HelloMarketing-2.svg" alt="HelloMarketing Logo" className="h-12" />
        </div>
      </div>
      {popup.show && <PopupMessage />}
    </div>
  );
};

export default App;